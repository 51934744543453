'use strict';

angular.module('windmanagerApp')
  .directive('componentMapCanvas', function ($window, appConfig, $state) {
    return {
      restrict: 'A',
      scope: {
        findings: '=',
        repairs: '=',
        disableNavigation: '=',
        page: '=',
        type: '='
        // findingRepairs: '=' // also display repairs(if present) with findings - changed Dec 20, 2017
      },
      link: function (scope, element, attrs) {
        scope.items = scope.findings;
        if(scope.type == 'repair') {
          scope.items = scope.repairs;
        }
        if(!scope.items || scope.items.length === 0) {
          return;
        }
        let w = angular.element($window);
        var canvas = element[0];
        let context = canvas.getContext('2d');
        // console.log('scope.page', scope.page);
        let tooltipConfig = {
          shown: false,
          w: 180,
          h: 120,
          xOffset: 20,
          lineHeight: 15,
          arrowWidth: 10,
          textPadding: 5,
          fillStyle: '#51677b'
        };
        let radius = 10;
        let font = radius + "px 'Open Sans'";

        let img = new Image();
        img.src = 'assets/images/blades.png';

        scope.getWindowDimensions = function () {
          return {
            'h': w.height(),
            'w': w.width()
          };
        };
        w.bind('resize', function () {
          scope.$apply();
        });

        img.onload = function () {
          draw(scope.getWindowDimensions());
          scope.$watch(scope.getWindowDimensions, (newValue, oldValue) => {
            draw(newValue);
          }, true);
        };

        /*Responsive*/
        function draw(dimensions) {
          if (dimensions.w < 768) {
            canvas.width = dimensions.w * 90 / 100;
          } else if (dimensions.w >= 768 && dimensions.w < 845) {
            canvas.width = dimensions.w * 45 / 100;
          } else if (dimensions.w >= 845 && dimensions.w < 1024) {
            canvas.width = dimensions.w * 50 / 100;
          } else if (dimensions.w >= 1024 && dimensions.w <= 1200) {
            canvas.width = dimensions.w * 37 / 100;
          } else if (dimensions.w > 1200 && dimensions.w <= 1360) {
            canvas.width = dimensions.w * 45 / 100;
          } else if (dimensions.w > 1360) {
            canvas.width = dimensions.w * 37 / 100;
          } else {
            canvas.width = dimensions.w * 37 / 100;
          }

          if(scope.page == 'print'){
            canvas.width = 500;
          }

          /// set size proportional to image
          canvas.height = canvas.width * (img.height / img.width);
          tooltipConfig.h = Math.min(tooltipConfig.h, canvas.height);
          tooltipConfig.lineHeight = tooltipConfig.h / 6;
          context.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.width * (img.height / img.width));

          drawDefects();
        }

        function getDotPosition(item, radius) {
          const marginLeft = 4 * canvas.width / 100; //4.91 is calculated manually-->   Left margin(30)*100/imageWidth
          const canvasWidth = canvas.width - ((3.7 * canvas.width / 100) + marginLeft);  //3.27 is calculated manually  -->Right margin(20)*100/imageWidth
          const canvasHeight = canvas.height;
          // FIX finding not visible if blade length is not defined or zero
          if(!(item.blade instanceof Object) || (item.blade instanceof Object && !item.blade.length)) {
            item.blade = { length: 40 };
          }
          const dpuHor = canvasWidth / item.blade.length; //Dots per unit Horizontally

          let dotPosX = (dpuHor * (item.distanceFromRoot || item.defectFrom) - (radius / 2)) + (marginLeft + (radius / 2));
          let dotPosY = 0;

          if ((item.bladeSide && item.bladeSide.toLowerCase().indexOf('suction') > -1) 
            || ( item.locationInBlade && item.locationInBlade.toLowerCase().indexOf('suction') > -1 )) {
            /*calculation:
             * factor=point on y axis*100/canvas height
             * So say defect at 0%, canvas height= 188px, 75px is the 0th position
             * calculation for factor= 75*100/188
             */
            if (item.chordDistance == '0%') {
              dotPosY = canvasHeight * 39.92 / 100;
            } else if (item.chordDistance == '0 - 25%') {
              dotPosY = canvasHeight * 36.38 / 100;
            } else if (item.chordDistance == '50 - 75%') {
              dotPosY = canvasHeight * 29.36 / 100;
            } else if (item.chordDistance == '75 - 100%') {
              dotPosY = canvasHeight * 25.85 / 100;
            } else {
              dotPosY = canvasHeight * 18.62 / 100;
            }
          } else {
            if (item.chordDistance == '0%') {
              dotPosY = canvasHeight * 96.80 / 100;
            } else if(item.chordDistance == '0 - 25%') {
              dotPosY = canvasHeight * 92.80 / 100;
            } else if (item.chordDistance == '25 - 50%') {
              dotPosY = canvasHeight * 89.29 / 100;
            } else if (item.chordDistance == '50 - 75%') {
              dotPosY = canvasHeight * 86.27 / 100;
            } else if (item.chordDistance == '75 - 100%') {
              dotPosY = canvasHeight * 82.76 / 100;
            } else {
              dotPosY = canvasHeight * 75.53 / 100;
            }
          }
          dotPosY = dotPosY - (radius / 2);
          return {
            x: dotPosX,
            y: dotPosY
          }
        }

        function drawDefects() {
          scope.items.forEach(item => {
            var dotPos = getDotPosition(item, radius);
            // repair color for repair type
            if(scope.type == 'repair') {
              // finding.category = 0;
              item.type = 'REPAIR';
              context.fillStyle = appConfig.colors['repair'];
            } else {
              context.fillStyle = appConfig.colors['cat' + item.category];
            }
            context.beginPath();
            context.arc(dotPos.x, dotPos.y, radius, 0, 2 * Math.PI);
            context.closePath();
            context.fill();

            context.fillStyle = "#FFFFFF"; // font color to write the text with
            context.font = font;
            context.textBaseline = "top";
            context.fillText(item.category || (item.type.toLowerCase()=='repair'?0:'-1'), dotPos.x - radius / 4, dotPos.y - radius);
          });

          // also draw repair finding is present
          /* if(scope.findingRepairs && scope.findingRepairs.length > 0) {
            scope.findingRepairs.forEach(finding => {
              var dotPos = getDotPosition(finding, radius);
              context.fillStyle = '#777';
              context.beginPath();
              context.arc(dotPos.x, dotPos.y, radius, 0, 2 * Math.PI);
              context.closePath();
              context.fill();

              context.fillStyle = "#FFFFFF"; // font color to write the text with
              context.font = font;
              context.textBaseline = "top";
              context.fillText(finding.category, dotPos.x - radius / 4, dotPos.y - radius);
            });
          } */
        }

        function drawTooltipArrow(tooltipX, tooltipY, dotPos, rightTooltip) {
          // a, b and c are 3 points of the triangle/arrow
          let ax = tooltipX;
          let ay = dotPos.y - tooltipConfig.arrowWidth / 2;
          let bx = tooltipX;
          let by = ay + tooltipConfig.arrowWidth;

          if (!rightTooltip) {
            ax += tooltipConfig.w;
            bx += tooltipConfig.w;
          }
          let dx = bx - ax;
          let dy = by - ay;
          let dangle = Math.atan2(dy, dx) - Math.PI / 3;
          let sideDist = Math.sqrt(dx * dx + dy * dy);

          let cx = Math.abs(Math.cos(dangle) * sideDist - ax * (rightTooltip ? 1 : -1));
          let cy = Math.abs(Math.sin(dangle) * sideDist + ay);

          context.beginPath();
          context.moveTo(ax, ay);
          context.lineTo(bx, by);
          context.lineTo(cx, cy);

          context.fill();
        }

        function showTooltip(item) {
          var dotPos = getDotPosition(item, radius);
          var totalToolipWidth = tooltipConfig.w + tooltipConfig.xOffset;
          var tooltipX = dotPos.x + tooltipConfig.xOffset;
          var tooltipY = Math.max(0, Math.min(dotPos.y - tooltipConfig.h / 2, canvas.height - tooltipConfig.h));
          var rightTooltip = true;
          var tooltipTextX = dotPos.x + tooltipConfig.xOffset + tooltipConfig.textPadding;
          if (dotPos.x > canvas.width - totalToolipWidth) {
            tooltipX = dotPos.x - totalToolipWidth;
            tooltipTextX = tooltipX + tooltipConfig.textPadding;
            rightTooltip = false;
          }
          context.fillStyle = tooltipConfig.fillStyle;
          context.beginPath();
          context.rect(tooltipX, tooltipY, tooltipConfig.w, tooltipConfig.h);
          context.closePath();
          context.fill();

          drawTooltipArrow(tooltipX, tooltipY, dotPos, rightTooltip);

          context.fillStyle = "#FFFFFF"; // font color to write the text with
          context.font = font;
          context.textBaseline = "top";
          if(item.type == 'repair') {
            context.fillText('Repair Done', tooltipTextX, tooltipY + tooltipConfig.lineHeight);
          } else {
            context.fillText('CAT: ' + item.category + ' (' + appConfig.categoryText[item.category] + ')', tooltipTextX, tooltipY + tooltipConfig.lineHeight);
          }
          context.fillText('Distance from Chord: ' + item.chordDistance, tooltipTextX, tooltipY + tooltipConfig.lineHeight * 2);
          context.fillText('Blade Side: ' + (item.bladeSide || item.locationInBlade), tooltipTextX, tooltipY + tooltipConfig.lineHeight * 3);
          context.fillText('Type : ' + item.type, tooltipTextX, tooltipY + tooltipConfig.lineHeight * 4);
          context.fillText('findingNo : ' + item.uniqueReferenceNumber || (item.type=='repair'?'repair':''), tooltipTextX, tooltipY + tooltipConfig.lineHeight * 5);
        }

        /* WIP: function scaleCanvas() {
          // finally query the various pixel ratios
          var devicePixelRatio = window.devicePixelRatio || 1;
          var backingStoreRatio = context.webkitBackingStorePixelRatio ||
            context.mozBackingStorePixelRatio ||
            context.msBackingStorePixelRatio ||
            context.oBackingStorePixelRatio ||
            context.backingStorePixelRatio || 1;

          var ratio = devicePixelRatio / backingStoreRatio;
          // upscale the canvas if the two ratios don't match
          if (devicePixelRatio !== backingStoreRatio) {

            var oldWidth = canvas.width;
            var oldHeight = canvas.height;

            canvas.width = oldWidth * ratio;
            canvas.height = oldHeight * ratio;

            canvas.style.width = oldWidth + 'px';
            canvas.style.height = oldHeight + 'px';

            // now scale the context to counter
            // the fact that we've manually scaled
            // our canvas element
            context.scale(ratio, ratio);
          }
        } */

        canvas.onmousemove = function (e) {
          let rect = this.getBoundingClientRect(),
            x = e.clientX - rect.left,
            y = e.clientY - rect.top;

          let hovered = false;
          scope.items.forEach(item => {
            var dotPos = getDotPosition(item, radius);
            var dx = x - dotPos.x;
            var dy = y - dotPos.y;
            if ((dx * dx + dy * dy) < (radius * radius)) {
              draw(scope.getWindowDimensions());
              showTooltip(item);
              hovered = true;
            }
          });
          if (!hovered) {
            draw(scope.getWindowDimensions());
          }
        };

        canvas.onclick = function (e) {
          let rect = this.getBoundingClientRect(),
            x = e.clientX - rect.left,
            y = e.clientY - rect.top;

          scope.items.forEach(item => {
            var dotPos = getDotPosition(item, radius);
            var dx = x - dotPos.x;
            var dy = y - dotPos.y;
            if ((dx * dx + dy * dy) < (radius * radius) && !scope.disableNavigation) {
              // if(scope.type == 'finding') {
              $state.go('finding', { blade: item.blade._id, id: item._id });
              // } else {
              //   $state.go('repair', { blade: item.blade._id, id: item._id });
              // }
            }
          });
        };
      }
    };
  });
